'use client';

import { AuthProvider } from '@/contexts/AuthProvider';
import { CartProvider } from '@/contexts/CartProvider';
import { LoginModelProvider } from '@/contexts/LoginModelProvider';
import { ProductProvider } from '@/contexts/ProductProvider';
import { StickyCartProvider } from '@/contexts/StickyCartProvider';
import { AgoraSignalingProvider } from '@/services/doc-consultation/context/AgoraSignalingContext';
import { LabAuthContextProvider } from '@labServices/context/LabAuthContext';
import { LabCartContextProvider } from '@labServices/context/LabCartContext';
import { SessionProvider } from 'next-auth/react';
const LayoutWrapper = ({ children }) => {
	return (
		<SessionProvider>
			<AuthProvider>
				<LabAuthContextProvider>
					<LoginModelProvider>
						<AgoraSignalingProvider>
							<CartProvider>
								<LabCartContextProvider>
									<StickyCartProvider>
										<ProductProvider>{children}</ProductProvider>
									</StickyCartProvider>
								</LabCartContextProvider>
							</CartProvider>
						</AgoraSignalingProvider>
					</LoginModelProvider>
				</LabAuthContextProvider>
			</AuthProvider>
		</SessionProvider>
	);
};

export default LayoutWrapper;
